import React from "react"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"

export default () => (
  <Layout>
    <HeadMeta pageTitle={"Resources and References"} />
    <PageHeader>Resources and References</PageHeader>
    <Breadcrumb
      route={[
        { name: "Parliamentary Papers", href: "/" },
        { name: "Resources and References", href: "/resources/" },
      ]}
    />
    <h2>Biographical Information</h2>
    <ul>
      <li>
        Parliament of New South Wales:{" "}
        <a href="https://www.parliament.nsw.gov.au/members/formermembers/Pages/former-members.aspx">
          List of former members
        </a>
      </li>
      <li>
        Lawlink New South Wales:{" "}
        <a href="http://www.lawlink.nsw.gov.au/lawlink/Corporate/ll_corporate.nsf/pages/attorney_generals_department_2007_03_26">
          Biographical notes on Attorneys General of New South Wales
        </a>
      </li>
      <li>
        Parliament of Australia: Parliamentary Library:{' '}
        <a href="http://www.aph.gov.au/library/handbook/historical/">
          Parliamentary Handbook of the Parliament of Australia
        </a>
      </li>
    </ul>
    <h2>Official Records</h2>
    <ul>
      <li>
        <a href="https://www.records.nsw.gov.au/">
          State Records New South Wales
        </a>
      </li>
      <li>
        <a href="https://www.naa.gov.au/">National Archives of Australia</a>
      </li>
    </ul>
    <h2>Manuscript Collections</h2>
    <ul>
      <li>
        <a href="http://www.nla.gov.au/raam/">
          Register of Australian Archives &amp; Manuscripts
        </a>{" "}
        (redirects to <a href="https://trove.nla.gov.au/">Trove</a>)
      </li>
      <li>
        <a href="https://www.nla.gov.au/what-we-collect/manuscripts">
          National Library of Australia
        </a>
      </li>
    </ul>
    <h2>General Resources</h2>
    <ul>
      <li>
        Heritage Office Website: Research: Heraldry:{' '}
        <a href="http://www.heritage.nsw.gov.au/statearms">
          How Brightly You Shine: Commemorating the centenary of the NSW Coat of
          Arms 1906 – 2006
        </a>
      </li>
      {/* https://webarchive.nla.gov.au/awa/20140309092411/http://www.efslearninghub.net.au/Heritage/research/heraldry/index.htm */}
      {/* <li>
        State Archives &amp; Records:{' '}
        <a href="https://www.records.nsw.gov.au/archives/collections-and-research/guides-and-indexes/nsw-coat-arms-guide">Coat of Arms (NSW Guide)</a>
      </li> */}
    </ul>
    <h2>References</h2>
    <ul>
      <li>
        <em>Australian Dictionary of Biography</em>. Melbourne: Melbourne
        University Press, 1966- , Vols. 1-16
      </li>
      <li>
        <a href="http://adb.anu.edu.au/">
          <em>Australian Dictionary of Biography Online</em>
        </a>
      </li>
      <li>
        C.N. Connolly,{" "}
        <em>Biographical Register of the New South Wales Parliament</em>,
        1856-1901. Canberra: Australian National University Press, 1983
      </li>
      <li>
        P. Loveday and H.M. Nelson,{" "}
        <em>
          Bibliography of Selected Manuscripts relating to Australian Politics
          since 1890 held in the Mitchell Library, Sydney
        </em>
        : Dept. of Government and Public Administration, University of Sydney,
        1964
      </li>
      <li>
        A.W. Martin and P. Wardle,{" "}
        <em>
          Members of the Legislative Assembly of New South Wales, 1856-1901
        </em>
        . Canberra: Australian National University Press, 1959
      </li>
      <li>
        <a href="http://www.parliament.nsw.gov.au/">
          <em>Parliament of New South Wales</em> website
        </a>{" "}
        -{" "}
        <a href="https://www.parliament.nsw.gov.au/members/pages/all-members.aspx">
          Members of Parliament
        </a>
      </li>
      <li>
        <a href="http://www.womenaustralia.info/exhib/pssb/home.html">
          <em>Putting skirts on the Sacred Benches</em> website
        </a>{" "}
        <br />
        Compiled by the Australian Women's Archives Project, this website lists
        more than 700 women who have stood as candidates for NSW Parliament
      </li>
      <li>
        Heather Radi, Peter Spearritt and Elizabeth Hinton,{" "}
        <em>
          Biographical Register of the New South Wales Parliament 1901-1970
        </em>
        . Canberra: Australian National University Press, 1979
      </li>
    </ul>
  </Layout>
)
